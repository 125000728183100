<template>
  <v-container fluid>
    <v-row justify="start" class="grey-2 mt-3">
      <v-col cols="12">
        <h2 class="grey--text">Sincronização - {{ isFinancialGroup ? 'Grupos Financeiros' : 'Operadoras'}}</h2>
      </v-col>
    </v-row>
    <v-form class="mt-7 white">
      <v-row class="mx-1">
        <v-col class="pt-5">
          <label class="grey--text font-weight-medium">Pesquisar</label>
          <v-divider class="mt-5"></v-divider>
        </v-col>
      </v-row>
      <v-row justify="start" class="mx-1">
        <v-col v-if="isFinancialGroup" cols="12" sm="6" md="3">
          <label class="label">Grupo Financeiro</label>
          <v-autocomplete
            v-model="financialGroupIds"
            :items="financialGroups"
            :loading="loadingFinancialGroups"
            :disabled="loadingFinancialGroups"
            item-text="name"
            item-value="id"
            color="newPrimary"
            outlined
            multiple
            placeholder="Selecione"
            clearable
            :search-input.sync="financialGroupIdsSearch"
            @change="financialGroupIdsSearch = null"
          >
          <template v-slot:selection="data">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mb-2" v-on="on" v-bind="attrs"> {{ data.item['name'] }} </v-chip>
                </template>
                <span>{{ data.item['name'] }}</span>
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-else cols="12" sm="6" md="3">
          <label class="label">Operadora</label>
          <v-autocomplete
            v-model="insuranceCarrierIds"
            :items="insuranceCarriers"
            :loading="loadingInsuranceCarriers"
            :disabled="loadingInsuranceCarriers"
            item-text="xipp_commercial_name"
            item-value="id"
            color="newPrimary"
            outlined
            multiple
            placeholder="Selecione"
            clearable
            :search-input.sync="insuranceCarrierIdsSearch"
            @change="insuranceCarrierIdsSearch = null"
          >
          <template v-slot:selection="data">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mb-2" v-on="on" v-bind="attrs"> {{ data.item['xipp_commercial_name'] }} </v-chip>
                </template>
                <span>{{ data.item['xipp_commercial_name'] }}</span>
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <label class="label">Tipo de configuração</label>
          <v-autocomplete
            v-model="managementTypes"
            :items="todoManagementTypes"
            color="newPrimary"
            outlined
            multiple
            placeholder="Selecione"
            clearable
            :search-input.sync="managementTypesSearch"
            @change="managementTypesSearch = null"
          >
            <template v-slot:selection="data">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mb-2" v-on="on" v-bind="attrs"> {{ data.item.text }} </v-chip>
                </template>
                <span>{{ data.item.text }}</span>
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <label class="label">Status</label>
          <v-autocomplete
            v-model="status"
            :items="managementStatus"
            item-value="value"
            item-text="text"
            placeholder="Selecione"
            outlined
            color="newPrimary"
            :search-input.sync="statusSearch"
            @change="statusSearch = null"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-btn
            class="white--text mt-6"
            style="height: 55px;"
            x-large
            block
            color="newPrimary"
            :loading="loadingManagementImplantations"
            :disabled="disableControl()"
            @click="onClickSearch();"
          >
            Pesquisar
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col class="pt-0">
          <label class="grey--text font-weight-medium">Sincronizar</label>
          <v-divider class="mt-5"></v-divider>
        </v-col>
      </v-row>
      <v-row justify="end" class="mx-1">
        <v-col align="end" cols="12">
          <v-btn
            class="white--text"
            x-large
            color="newPrimary"
            :loading="loadSync"
            :disabled="selectedItems.length === 0"
            @click="onClickSync()"
          >
            <v-icon class="mr-2" size="24px">
              mdi mdi-refresh
            </v-icon>
            Sincronizar Selecionados
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-data-table
            v-model="selectedItems"
            class="elevation-1"
            show-select
            item-key="index"
            @toggle-select-all="handleSelectionChange"
            @update:options="changePage($event)"
            :options.sync="options"
            :headers="configurationHeader"
            :items="managementImplantations"
            :page="page"
            :loading="loadingManagementImplantations"
            :server-items-length="totalPages"
            :items-per-page="itemsPerPage"
            :footer-props="{
              itemsPerPageOptions: [25, 50, 75, 100, -1],
              disablePagination: loadingManagementImplantations,
            }"
          >
            <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
              <v-simple-checkbox
                :value="isSelected"
                :readonly="item.disabled"
                :disabled="item.disabled"
                color="primary"
                @input="select($event)"
              />
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span>{{ item.name ? item.name : '-' }}</span>
            </template>
            <template v-slot:[`item.dateLastExecution`]="{ item }">
              <span>{{ item.dateLastExecution ? formatter.formatDateTimeZoneWithHours(item.dateLastExecution) : '-' }}</span>
            </template>
            <template v-slot:[`item.entityName`]="{ item }">
              <span>{{ item.entityName ? item.entityName : '-' }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-btn :style="{ cursor: 'auto' }" dark :color="formatedStatus(item.status).color" prepend-icon="mdi-check-circle">
                <v-icon size="16px" class="mr-2">{{ formatedStatus(item.status).icon }}</v-icon>
                {{ formatedStatus(item.status).text }}
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>

    <v-footer class="mt-10">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn x-large block outlined color="textPrimary" @click="onClickBack()">
            Voltar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>

<script>
import Rules from '@/shared/validators/formRules';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import ManagementImplantationService from '@/services-http/sdi/ManagementImplantationService';
import Formatters from '@/shared/formatters/formatters';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';

export default {
  name: 'ManagementSyncs',
  components: { SnackbarCustomize },
  data: () => ({
    financialGroupIdsSearch: null,
    insuranceCarrierIdsSearch: null,
    managementTypesSearch: null,
    statusSearch: null,
    loadingFinancialGroups: false,
    loadingInsuranceCarriers: false,
    loadingManagementImplantations: false,
    isFinancialGroup: false,
    isInsuranceCarrier: false,
    loadSync: false,
    isRHProtegido: false,

    page: 1,
    totalPages: 0,
    itemsPerPage: 10,

    options: {},

    status: 'PENDING_UPDATE',
    entityType: '',
    financialGroupIds: [],
    insuranceCarrierIds: [],
    managementTypes: [],
    financialGroups: [],
    insuranceCarriers: [],
    managementSyncs: [],
    selectedItems: [],
    managementImplantations: [],
  }),

  mixins: [
    FinancialGroupsMixin,
    InsuranceCarriersMixin,
    ConstantsMixin,
    VerifyRoutesMixin,
  ],

  async mounted() {
    await this.verifyManagementType();
  },

  computed: {
    configurationHeader() {
      const commonHeaders = [
        {
          text: 'Tipo de configuração',
          value: 'name',
          align: 'start',
          sortable: false,
          class: 'text--truncate',
          width: '25%',
        },
        {
          text: 'Última Execução',
          value: 'dateLastExecution',
          align: 'start',
          sortable: false,
          class: 'text--truncate',
          width: '25%',
        },
        {
          text: 'Status',
          value: 'status',
          align: 'start',
          sortable: false,
          class: 'text--truncate',
          width: '25%',
        },
      ];

      if (this.isFinancialGroup) {
        commonHeaders.splice(1, 0, {
          text: 'Grupo Financeiro',
          value: 'entityName',
          align: 'start',
          sortable: false,
          class: 'text--truncate',
          width: '25%',
        });
      } else if (this.isInsuranceCarrier) {
        commonHeaders.splice(1, 0, {
          text: 'Operadora',
          value: 'entityName',
          align: 'start',
          sortable: false,
          class: 'text--truncate',
          width: '25%',
        });
      }

      return commonHeaders;
    },
    todoManagementTypes() {
      if (this.isFinancialGroup) {
        return [
          {
            value: 'COLLECTOR_CLIENT',
            text: 'Coletor',
          },
          {
            value: 'COLLECTOR_PAYROLL',
            text: 'Coletor Folha',
          },
          {
            value: 'LAYOUT_CLIENT',
            text: 'Layout',
          },
          {
            value: 'LAYOUT_PAYROLL',
            text: 'Layout Folha',
          },
          {
            value: 'MOVEMENT_RULES_CLIENT',
            text: 'Regras Movimentação',
          },
        ];
      }
      if (this.isInsuranceCarrier) {
        return [
          {
            value: 'GENERATE_MOVEMENT_INSURANCE_CARRIER',
            text: 'Geração de Lote',
          },
          {
            value: 'LAYOUT_INSURANCE_CARRIER_SEND',
            text: 'Layout Saída',
          },
        ];
      }
      return [];
    },
  },

  methods: {
    async verifyManagementType() {
      const { name: routeName } = this.$route;

      this.isFinancialGroup = routeName === 'FinancialGroupSync';
      this.isInsuranceCarrier = routeName === 'InsuranceCarrierSync';

      if (this.isFinancialGroup) {
        await this.getFinancialGroups();
        this.entityType = 'FINANCIAL_GROUP';
        if (this.financialGroups && this.financialGroups.length === 1) {
          this.financialGroupIds.push(this.financialGroups[0].id);
        }
      } else if (this.isInsuranceCarrier) {
        await this.getInsuranceCarriers();
        this.entityType = 'INSURANCE_CARRIER';
      }
    },
    onClickSearch() {
      const searchParams = this.createSearchParams();
      this.searchManagementImplantations(searchParams);
    },
    createSearchParams() {
      let payload = {};
      payload = {
        financialGroupIds: this.financialGroupIds ? this.financialGroupIds : null,
        insuranceCarrierIds: this.insuranceCarrierIds ? this.insuranceCarrierIds : null,
        managementTypes: this.managementTypes,
        managementStatus: this.status,
        entityType: this.entityType,
      };

      if (this.isFinancialGroup) {
        this.checkFinancialGroups(payload);
      } else {
        this.checkInsuranceCarriers(payload);
      }

      return payload;
    },
    checkFinancialGroups(payload) {
      if (!this.financialGroupIds || this.financialGroupIds.length === 0) {
        const arrayAux = this.financialGroups.map((element) => element.id);
        // eslint-disable-next-line no-param-reassign
        payload.financialGroupIds = arrayAux;
      }
    },

    checkInsuranceCarriers(payload) {
      if (!this.insuranceCarrierIds || this.insuranceCarrierIds.length === 0) {
        const arrayAux = this.insuranceCarriers.map((element) => element.id);
        // eslint-disable-next-line no-param-reassign
        payload.insuranceCarrierIds = arrayAux;
      }
    },
    async searchManagementImplantations(searchParams) {
      this.loadingManagementImplantations = true;


     const parameterFilter = Object.entries(searchParams).filter(([key, value]) => value !== null && value.length !== 0).reduce((params, [key, value]) => ({ ...params, [key]: value }), {});
      let payload = {
        entityType: parameterFilter.entityType
      };

      if(parameterFilter.managementTypes){
        payload.managementTypes = parameterFilter.managementTypes
      }

      if(parameterFilter.managementStatus){
        payload.managementStatus = [parameterFilter.managementStatus]
      }

      if(parameterFilter.financialGroupIds){
        payload.financialGroupIds = parameterFilter.financialGroupIds
      }
      if(parameterFilter.insuranceCarrierIds){
        payload.insuranceCarrierIds = parameterFilter.insuranceCarrierIds;
      }

      console.log(payload)
      this.managementImplantationService.FindManagementImplantationsByFilter(payload).then((response) => {
        if (response && response.data) {
          this.managementImplantations = response.data;
          this.totalPages = response.totalPages;
          this.adjustedManagementImplantations();
        } else {
          this.managementImplantations = [];
        }
        this.loadingManagementImplantations = false;
      }).catch(() => {
        this.loadingManagementImplantations = false;
      });
    },
    adjustedManagementImplantations() {
      let count = 0;
      this.managementImplantations.forEach((element) => {
        if (element.status !== 'PENDING_UPDATE' && element.status !== 'IMPLANTED' && element.status !== 'ERROR_PROCESSING') {
          element.disabled = true;
        }
        // eslint-disable-next-line no-plusplus, no-param-reassign
        element.index = count++;
      });
    },
    formatedStatus(status) {
      if (status) {
        if (status === 'NOT_CONFIGURED') return { icon: 'mdi mdi-block-helper', color: 'grey', text: 'Configuração Inexistente' };
        if (status === 'PENDING_UPDATE') return { icon: 'mdi mdi-alert-outline', color: 'orange', text: 'Pendente Sincronização' };
        if (status === 'ERROR_PROCESSING') return { icon: 'mdi mdi-close', color: 'red', text: 'Falha no Processamento' };
        if (status === 'AWAITING_PROCESSING') return { icon: 'far fa-clock', color: '#0b78c0', text: 'Aguardando Processamento' };
        if (status === 'PROCESSING') return { icon: 'fas fa-hourglass-half', color: '#0b78c0', text: 'Em Fila de Processamento' };
        if (status === 'IMPLANTED') return { icon: 'mdi mdi-check', color: 'success', text: 'Configuração Ativa' };
        if (status === 'WAIT_START') return { icon: 'mdi mdi-play-outline', color: 'blue', text: 'Em Processamento' };
        if (status === 'FIXED_CONFIGURATION') return { icon: 'mdi mdi-check', color: 'success', text: 'Configuração Fixa' };
      }
      return null;
    },
    handleSelectionChange(data) {
      if (this.rule.isEmptyArray(this.selectedItems)) {
        const foundSelected = data.items.filter((item) => item.status === 'PENDING_UPDATE' || item.status === 'IMPLANTED' || item.status === 'ERROR_PROCESSING');
        this.selectedItems = foundSelected;
      } else {
        this.selectedItems = [];
      }
    },
    async onClickSync() {
      this.loadSync = true;
      const payload = [];
      this.selectedItems.forEach((element) => {
        if (this.isFinancialGroup) {
          payload.push({
            financialGroupId: element.entityId,
            managementType: element.type,
          });
        } else {
          payload.push({
            insuranceCarrierId: element.entityId,
            managementType: element.type,
          });
        }
      });

      await this.managementImplantationService.ManagementImplantationsSync(this.entityType, payload).then(() => {
        setTimeout(() => {
          this.selectedItems = [];
          this.$refs.SnackbarCustomize.open('blue', 'A sincronização iniciará em instantes', 5000);
          this.loadSync = false;
          this.onClickSearch();
        }, 1000);
      }).catch(() => {
        this.loadSync = false;
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao sincronizar');
      });
    },
    changePage() {
      this.selectedItems = [];
    },
    onClickBack() {
      this.redirectRouter('HomeAllMenus', { isRHProtegido: this.isRHProtegido });
    },
    disableControl() {
      return (this.financialGroups && this.financialGroups.length === 0) && (this.insuranceCarriers && this.insuranceCarriers.length === 0);
    },
  },

  created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.managementImplantationService = new ManagementImplantationService();
  },
};
</script>
